'use client'
import { v4 as uuid } from 'uuid'
import axios from 'axios'
import * as Cookies from 'js-cookie'
import * as qs from 'qs'

const domain =
  process.env.NODE_ENV === 'development' ? 'localhost' : 'codeverse.com'
process.env.NODE_ENV === 'development' ? 'localhost' : 'codeverse.com'
export let apiUrl = process.env.CODEVERSE_API_URL

let QueryString: any = {}
if (typeof window !== 'undefined') {
  QueryString = qs.parse(window.location.search, { ignoreQueryPrefix: true })
}

if (typeof window !== 'undefined') {
  window.Cookies = Cookies
}

export const start = (api?: string) => {
  let clientToken = localStorage.getItem('clientToken')
  const sessionToken = localStorage.getItem('sessionToken')
  // update url if given
  apiUrl = api || apiUrl
  if (!clientToken) {
    // create a unique id
    clientToken = uuid()

    // store the unique id in our cookies for 10 years
    const expires = new Date().setFullYear(new Date().getFullYear() + 10)
    localStorage.setItem('clientToken', clientToken)
    // Cookies.set('clientToken', clientToken, {
    //   domain,
    //   expires,
    // })
  }

  if (!sessionToken) {
    // create a unique id
    let newSessionToken

    try {
      newSessionToken = uuid()
    } catch (e) {
      console.log('Failed to create UUID')
    }

    const data = {
      id: newSessionToken,
      client_token: clientToken,

      // // standard
      // user_agent: 'user_agent',
      // http_referrer: 'referrer',
      // landing_page: 'landing_page',

      // // traffic source
      referring_domain: document.referrer,
      // search_keyword: 'search_keyword',

      // // technology
      // browser: 'browser',
      // os: 'os',
      // os_version: 'os_version',
      // device_type: 'device_type',

      screen_height: document.documentElement.clientHeight,
      screen_width: document.documentElement.clientWidth,

      // utm parameters
      utm_source: QueryString.utm_source,
      utm_medium: QueryString.utm_medium,
      utm_term: QueryString.utm_term,
      utm_content: QueryString.utm_content,
      utm_campaign: QueryString.utm_campaign,
      gclid: QueryString.gclid,
      fbclid: QueryString.fbclid,
      // // A/B tests
      // test_version: 'test_version',

      // apps
      // platform: 'platform',
      // app_version: 'app_version'
    }

    return axios({
      method: 'post',
      url: `${apiUrl}/public_sessions`,
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
      data: {
        data: {
          type: 'public_sessions',
          attributes: {
            ...data,
          },
        },
      },
    })
      .then(function () {
        console.log('API CREATED TOKEN')
        // store the unique id in our cookies for 1 day
        const expires = 1
        localStorage.setItem('sessionToken', newSessionToken)
        // Cookies.set('sessionToken', newSessionToken, {
        //   domain,
        //   expires,
        //   secure: true,
        // })
        return newSessionToken
      })
      .catch(() => {
        throw new Error('Failed to create public_session token')
      })
  }

  // console.log('Cookies', Cookies);
  // console.log(Cookies.get('a'));
}

'use client'
import {
  faCheck,
  faCheckCircle,
  faCircleExclamation,
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { toast, ToastContainer } from 'react-toastify'

const contextClass = {
  success: '!bg-titan-dark',
  error: 'bg-mars',
  info: 'bg-gray-600',
  warning: 'bg-orange-400',
  default: 'bg-indigo-600',
  dark: 'bg-white-600 font-gray-300',
}
import 'react-toastify/dist/ReactToastify.css'
export default function ToastContainerClient() {
  return (
    <ToastContainer
      toastClassName={(context: any) =>
        contextClass[context?.type || 'default'] +
        ' ' +
        'relative flex h-12 rounded-[32px] justify-between overflow-hidden cursor-pointer !w-auto'
      }
      bodyClassName={() =>
        'font-med flex flex-row text-[#170B2B] font-jakarta-sans font-semibold !pr-9 justify-center items-center pl-3'
      }
      icon={(context: any) => {
        switch (context.type) {
          case 'success':
            return <FontAwesomeIcon icon={faCheckCircle} size="xl" />
          case 'error':
            return (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                size="xl"
                style={{
                  //@ts-ignore
                  '--fa-primary-color': '#fff',
                  '--fa-primary-opacity': '0',
                  '--fa-secondary-color': '#D10032',
                }}
              />
            )
          case 'info':
            return <FontAwesomeIcon icon={faInfoCircle} size="xl" />
          case 'warning':
            return <FontAwesomeIcon icon={faExclamationTriangle} size="xl" />
          default:
            return <FontAwesomeIcon icon={faCheckCircle} size="xl" />
        }
      }}
      closeButton={(props) => {
        return (
          <div
            {...props}
            onClick={() => {
              toast.dismiss()
            }}
            className="flex flex-row items-center justify-center pr-4 text-[#170B2B]"
          >
            <FontAwesomeIcon icon={faTimes} size="xl" />
          </div>
        )
      }}
      autoClose={5000}
      className={'!w-auto'}
    />
  )
}

'use client'
import React, { useMemo } from 'react'
import useMediaQuery from './useResponsiveWrapper'

import {
  MOBILE_BREAKPOINT,
  SMALL_MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  TOOLBOX_BREAKPOINT,
} from '../defaults/ResponsiveBreakpoints'

const ResponsiveContext = React.createContext<any>(null)

interface useResponsiveProps {}

type ResponsiveState = {
  isMobile: boolean
  isTablet: boolean
  isSmallMobile: boolean
  isSmallToolbox: boolean
}

// interface HookOutput {
//   ResponsiveState
// }

function ResponsiveProvider(props: useResponsiveProps) {
  const isMobile = useMediaQuery({ query: MOBILE_BREAKPOINT })
  const isTablet = useMediaQuery({ query: TABLET_BREAKPOINT })
  const isSmallToolbox = useMediaQuery({ query: TOOLBOX_BREAKPOINT })
  const isSmallMobile = useMediaQuery({ query: SMALL_MOBILE_BREAKPOINT })
  const state = useMemo(
    () => ({ isMobile, isTablet, isSmallMobile, isSmallToolbox }),
    [isMobile, isTablet, isSmallMobile]
  )

  const value = React.useMemo(() => [state], [state])

  return <ResponsiveContext.Provider value={value} {...props} />
}

const useResponsive = () => {
  const context = React.useContext(ResponsiveContext)
  if (!context) {
    throw new Error(`useResponsive must be used within a ResponsiveProvider`)
  }

  const [state]: [ResponsiveState] = context

  return {
    isMobile: state.isMobile,
    isTablet: state.isTablet,
    isSmallToolbox: state.isSmallToolbox,
    isSmallMobile: state.isSmallMobile,
  }
}

export { ResponsiveProvider, useResponsive, ResponsiveContext }

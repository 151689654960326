'use client'

import { useEffect } from 'react'
import { start } from 'apps/studio-shared/src/Util/public-session-tracker'

export default function PublicSession() {
  useEffect(() => {
    start('https://api.codeverse.com')
  }, [])

  return null
}
